<template>
  <b-container>
    <!-- {{ thisBusiness }} -->
    <b-form 
       @submit="onSubmit" 
       @reset="onReset" v-if="show">
       
      <!-- EVENT LOCATION -->
      <fieldset>
        <legend>Event Location</legend>
        <b-form-group
          description="Location of the event"
          label="Select event location"
          label-for="event-location"
          :label-cols="12"
	        :label-cols-md="3">
          <b-form-select 
          id="event-location" 
          v-model="event.location" 
          :options="eventLocationOptions" 
          value-field="@id"
          text-field="name"
          first="Please Select a Location"
          required />
        </b-form-group>
        
        <template v-if="event.location === 'new'">
        <b-card title="New Location" class="animated fadeIn">
          
          <b-form-group
            label="Location Search"
            label-for="location-search"
            description="Use of this feature is strongly advised. Type the name as it is seen in Google for easiest results."
            :label-cols="12"
	          :label-cols-md="3"
          >
            <gmap-autocomplete value="" id="location-search"
              placeholder="Find By Name (preferred) or Address"
              @place_changed="setPlace"
              class="form-control"
              :select-first-on-enter="true"
              :options="gMapOpts"
            ></gmap-autocomplete>
          </b-form-group>
          <hr />
          <b-form-group
            label="Location Name"
            label-for="location-name"
            :label-cols="12"
	          :label-cols-md="3"
          >
            <b-form-input 
              id="location-name" 
              v-model="location.name" 
              type="text" 
              maxlength="150" 
              placeholder="Name of the venue" 
              required
            ></b-form-input>
          </b-form-group>
          
          <b-form-group class="clearfix">
            <b-form-row>
              <b-col sm="3" class="col-form-label">Location Address</b-col>
              <b-col sm="5"><b-form-input id="location-address1" v-model="location.address1" type="text" maxlength="150" placeholder="Street Address" required></b-form-input></b-col>
              <b-col sm="2"><b-form-input id="location-address2" v-model="location.address2" type="text" maxlength="150" placeholder="Suite # (optional)"></b-form-input></b-col>
              <b-col sm="2"><b-form-input id="location-postalCode" v-model="location.postalCode" type="text" maxlength="150" placeholder="Zip Code" required></b-form-input></b-col>
            </b-form-row>
          </b-form-group>
          
          <div class="offset-md-3"><b-btn variant="success" @click="addEventLocation" block><b-spinner v-if="newLocationPending" small type="grow"></b-spinner> Save this location</b-btn></div>
        </b-card>
        </template>
      </fieldset>
      
      <!-- EVENT DETAILS -->
	    <fieldset>
	      <legend>Event Details</legend>
        <b-form-group
          description="Status of event"
          label="Select event status"
          label-for="event-status"
          :label-cols="12"
	        :label-cols-md="3">
          <b-form-select id="event-status" v-model="event.status" :options="eventStatusOptions" required />
        </b-form-group>
        
	      <b-form-group
	        description="Type of event"
	        label="Select event type"
	        label-for="event-type"
	        :label-cols="12"
	        :label-cols-md="3">
	        <b-form-select 
            id="event-type" 
            v-model="event.type" 
            :options="eventTypeOptions" 
            value-field="@id"
            text-field="label"
            required />
	      </b-form-group>
	      
	      <b-form-group
	        description="Sport being played"
	        label="Select event sport"
	        label-for="event-sport"
	        :label-cols="12"
	        :label-cols-md="3">
	        <b-form-select 
                id="event-sport" 
                v-model="event.sport" 
                :options="sportsOptions"
                value-field="@id"
                text-field="label"
                required />
	      </b-form-group>
	          
	      <b-form-group
	        description="Title of the event"
	        label="Enter event title"
	        label-for="event-title"
	        :label-cols="12"
	        :label-cols-md="3">
	        <b-form-input id="event-title" v-model="event.title" type="text" maxlength="150" placeholder="SEO-friendly title for the event" required></b-form-input>
	       </b-form-group>
	          
	      <b-form-group
	        description="Summary of the event"
	        label="Enter brief summary (optional)"
	        label-for="event-summary"
	        :label-cols="12"
	        :label-cols-md="3">
	        <b-form-textarea 
	          id="event-summary" 
	          v-model="event.summary" 
	          placeholder="Brief summary of the event"
	          rows="2"
	          max-rows="3"
	        />
	      </b-form-group>
	          
        <b-form-group
          description="Detailed description of the event"
          label="Enter event description"
          label-for="event-description"
          :label-cols="12"
          :label-cols-md="3">
          <b-form-textarea
            id="event-description"
            v-model="event.description"
            placeholder="Detailed description of the event"
            rows="5"
            max-rows="15"
            required
          />
        </b-form-group>

        <b-form-group
          label-for="eventWelcome-custom"
          :label-cols="12"
          :label-cols-md="3"
        >
          <b-form-checkbox
            id="eventWelcome-custom"
            v-model="eventWelcome.custom"
            name="eventWelcome_custom"
            :value="true"
            :unchecked-value="false"
          >I have a custom 'Welcome' email message for this event.</b-form-checkbox>
        </b-form-group>

        <b-form-group
          v-if="eventWelcome.custom"
          description="Custom 'Welcome' message for the event"
          label="Custom 'Welcome' Message"
          label-for="event-welcome"
          :label-cols="12"
          :label-cols-md="3">
          <b-form-textarea
            id="event-welcome"
            v-model="eventWelcome.message"
            placeholder="The custom 'Welcome' message for the event (optional)..."
            rows="5"
            max-rows="15"
            required
          />
        </b-form-group>
        
        <b-form-group>
          <b-form-row>
            <b-col sm="3" class="col-form-label">Age Requirements</b-col>
            <b-col xs="2">
              <b-form-group>
                <label for="event-ageMin" class="sr-only">Enter the min age</label>
                <b-form-input 
                  type="number" 
                  v-model="event.ageMin" 
                  min="0" 
                  max="200" 
                  size="3" 
                  maxlength="3" 
                  id="event-ageMin" 
                  placeholder="min" 
                  required 
                  />
              </b-form-group>
            </b-col>
            <b-col xs="2">
              <b-form-group>
                <label for="event-ageMax" class="sr-only">Enter the max age</label>
                <b-form-input 
                  type="number" 
                  v-model="event.ageMax" 
                  min="0" 
                  max="200" 
                  size="3" 
                  maxlength="3" 
                  id="event-ageMax" 
                  placeholder="max" 
                  required
                  />
              </b-form-group>
            </b-col>
            <b-col></b-col>
          </b-form-row>
        </b-form-group>
        
        <b-form-group>
          <b-form-row>
            <b-col sm="3" class="col-form-label">Post & Event Dates</b-col>
            <b-col sm="2">
              <b-form-group>
                <label for="event-postDate">Event Post Date</label>
                <v-date-picker
                  v-model="event.postDate" 
                  id="event-postDate" 
                  :input-props="{
                    class: 'form-control'
                  }"
                  @change="pricing[0].date = event.postDate"
                  required 
                />
                <small class="form-text text-muted">Date the event posts</small>
              </b-form-group>
            </b-col>
            <b-col sm="2">
              <b-form-group>
                <label for="event-startDate">Event Start Date</label>
                <v-date-picker
                  v-model="event.startDate" 
                  id="event-startDate"
                  required
                  :input-props="{
                    class: 'form-control'
                  }"
                />
                <small class="form-text text-muted">Date the event begins</small>
              </b-form-group>
            </b-col>
            <b-col sm="3">
              <b-form-group>
                <label for="event-durationFrequency">Event Duration</label>
                <div>
                  <b-form-select 
                    class="autowidth float-left" 
                    id="event-durationFrequency" 
                    v-model="event.durationFrequency" 
                    :options="[{ value: 'day', text: 'Days' }, { value: 'weeks', text: 'Weeks' }, { value: 'months', text: 'Months' } , { value: null, text: 'Ongoing' } ]"
                  ></b-form-select>
                  <b-form-input 
                    v-if="event.durationFrequency"
                    type="number" 
                    min="0"
                    max="52"
                    size="2"
                    maxlength="2"
                    class="autowidth"
                    v-model="event.duration" 
                    id="event-numWeeks"
                    required
                  ></b-form-input>
                </div>
              </b-form-group>
            </b-col>
            <b-col sm="2">
              <b-form-group
                v-if="event.durationFrequency"
                label="Registration Cut-Off Date"
                label-for="event-registrationEnds"
              >
                <v-date-picker
                  id="event-registrationEnds" 
                  v-model="event.registrationEnds" 
                  required
                  :input-props="{
                    class: 'form-control autowidth'
                  }"
                />
                <small class="form-text font-weight-bold text-primary">The date registration is disabled.</small>
              </b-form-group>
            </b-col>
          </b-form-row>
        </b-form-group>

        <template v-if="this.event.type === '/api/event_types/1'">
          <b-form-group
            label-for="event-adminFee-required"
            :label-cols="12"
            :label-cols-md="3"
          >
            <b-form-checkbox
              id="event-adminFee-required"
              v-model="adminFeeRequired"
              name="eventAdminFeeRequired"
              :value="true"
              :unchecked-value="false"
            >An Admin fee is required for this event.</b-form-checkbox>
          </b-form-group>

          <b-form-group v-if="adminFeeRequired && event.otherFees">
            <b-form-row>
              <b-col sm="3">&nbsp;</b-col>
              <b-col>
            <label for="event-adminFee">Admin Fee</label>
            <b-input-group prepend="$" class="mb-2">
              <b-form-input 
                v-model.number="event.otherFees.adminFee.amount" 
                class="autowidth"
                type="number" 
                min="0" 
                max="1000" 
                step="0.01" 
                placeholder="Admin Fee" 
                required
                />
            </b-input-group>
            </b-col>
            </b-form-row>
          </b-form-group>
        </template>
	    </fieldset>
      
      <fieldset>
        <legend>Event Pricing</legend>
        <p class="bg-info p-2 offset-md-3">
          <i class="fa fa-info-circle"></i> Site registration begins on the date of the first pricing tier.
        </p>
	      <b-form-group>
	        <b-form-row>
	          <b-col sm="3">&nbsp;</b-col>
	          <b-col>
	            <!-- div class="bg-light px-2"><p><b>INFO: Setting a pricing date to today or a past date will set the event to OPEN upon saving.</b></p></div -->
				      <b-card v-for="(teir, index) in pricing" class="mb-2" :key="index">
		            <label class="sr-only">Label</label>
		            <b-form-select v-if="pricing.length > 1" v-model="teir.tierLabel" name="pricing[][tierLabel]" class="mb-2" :options="[
                  {value: 'First Day Registration', text: 'First Day Registration'},
                  {value: 'Early Registration', text: 'Early Registration'},
                  {value: 'Standard Registration', text: 'Standard Registration'},
                  {value: 'Late Registration', text: 'Late Registration'},
                  {value: 'Day-Of Registration', text: 'Day-Of Registration'},
                  {value: null, text: 'Pricing Label'}
		            ]" />
					      <b-row>
				          <b-col sm="5">
				            <label class="sr-only">Date</label>
                    <v-date-picker
				              v-model="teir.datetimeStart"
				              name="pricing[][datetimeStart]" 
				              placeholder="Date" 
				              class="mb-2"
				              required 
                      :input-props="{
                        class: 'form-control'
                      }"
                    />
				          </b-col>
				          <b-col sm="4">
				            <label class="sr-only">Price</label>
				            <b-input-group prepend="$" class="mb-2">
				              <b-form-input 
				                v-model="teir.price"
				                name="pricing[][price]" 
				                type="number" 
				                min="0" 
				                max="1000" 
				                step="0.01" 
				                placeholder="Price" 
				                required
				                />
				            </b-input-group>
				          </b-col>
				          <b-col sm="3">
				            <label class="sr-only">Remove</label>
				            <button v-if="pricing.length > 1" type="button" v-on:click="removePricing(index)" class="btn btn-block btn-danger">
				                Rem -
				            </button>
				          </b-col>
					      </b-row>
				          <b-form-checkbox  v-if="(teir.price > 0)"
		                :value="true"
		                :unchecked-value="false"
		                v-model="teir.payByCheck"
                    name="pricing[][payByCheck]" 
		                >Allow Pay-by-Check
		              </b-form-checkbox>
			        </b-card>
		        </b-col>
	        </b-form-row>
	        <div class="row mb-2">
	            <b-col sm="3"></b-col>
	            <b-col>
	                <b-button variant="success" type="button" v-on:click="addNewPricing" block>
	                    Add Pricing Teir
	                </b-button>
	            </b-col>
	        </div>
        </b-form-group>
      </fieldset>
      
      <fieldset>
        <legend>Limit <small>(optional)</small></legend>
        <b-form-group>
	        <b-form-row>
	          <b-col sm="3"></b-col>
	          <b-col lg="1">
	            <b-form-group>
	              <label for="year1">Spots</label>
	              <b-form-input v-model="event.regRemaining" type="number" min="0" max="900" size="3" maxlength="3" id="event-spots"></b-form-input>
	              <small class="form-text text-muted d-block d-lg-none">A static value of spots left (0 = disabled)</small>
	            </b-form-group>
	          </b-col>
	          <b-col lg="1">
	            <b-form-group>
	              <label for="cvv">Limit</label>
	              <b-form-input v-model="event.regLimit" type="number" min="0" max="900" size="3" maxlength="3" id="reg-limit"></b-form-input>
	              <small class="form-text text-muted d-block d-lg-none">Registation Limit</small>
	            </b-form-group>
	          </b-col>
	          <b-col lg="1">
	            <b-form-group>
	              <label for="cvv">Threshold</label>
	              <b-form-input v-model="event.regLimitThreshold" type="number" min="0" max="900" size="3" maxlength="3" id="reg-limit-thresh"></b-form-input>
	              <small class="form-text text-muted d-block d-lg-none">Registation Limit Notification Threshold</small>
	            </b-form-group>
	          </b-col>
            <b-col>
            <p class="d-none d-lg-block">
              <b>Spots</b> - (static / 0 = disabled) - A static value of spots left<br />
              <b>Limit</b> - Registation Limit<br />
              <b>Threshold</b> - Registation Limit Notification Threshold<br />
            </p>
            </b-col>
	        </b-form-row>
        </b-form-group>
      </fieldset>
      
      <fieldset>
        <legend>Questions <small>(optional)</small></legend>
        <b-form-group>
          <b-form-row>
            <b-col sm="3">&nbsp;</b-col>
            <b-col>
              <!-- div class="bg-light px-2"><p><b>INFO: Setting a pricing date to today or a past date will set the event to OPEN upon saving.</b></p></div -->
              <b-card v-for="(theQuestion, index) in event.questions" class="mb-2" :key="index">
                <b-row class="my-2">
                  <b-col sm="6">
                    <b-form-select 
                      v-model="event.questions[index]" 
                      :options="questionOptions"
                      value-field="@id"
                      text-field="label"
                      first="Please Select Question" 
                      name="event.questions[]" required  />
                  </b-col>
                  <b-col sm="3">
                    <label class="sr-only">&nbsp;</label>
                    <button type="button" v-on:click="removeQuestion(index)" class="btn btn-block btn-danger">
                        Rem -
                    </button>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-form-row>
        </b-form-group>
          <div class="row mb-2">
              <b-col sm="3"></b-col>
              <b-col>
                  <b-button variant="success" type="button" v-on:click="addNewQuestion" block>
                      Add Question
                  </b-button>
              </b-col>
          </div>
      </fieldset>
      
      <hr />
      
      <div class="offset-md-3">
        <b-button type="reset" variant="danger">Reset the Form</b-button>
        <b-button type="submit" id="event-form-submit" variant="success" class="float-right">Submit This Event</b-button>
      </div>
    </b-form>
  </b-container>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import { constants } from '@/shared/constants'  

const DEFAULT_DURATION_UNIT = 'weeks'
// const DEFAULT_DURATION = 1
const ONGOING_EVENT_TYPES = ['/api/event_types/1']
const LOCATION = {
  business: null,
  name: null,
  address1: null,
  address2: null,
  postalCode: null,
  country: 'US',
  lat: null,
  lng: null
}
const EVENT = {
  id: null,
  business: null,
  status: '/api/event_statuses/1',
  location: null,
  type: null,
  sport: null,
  title: '',
  summary: '',
  description: '',
  ageMin: '',
  ageMax: '',
  registrationEnds: null,
  payByCheck: true, // allow pay by check
  postDate: null,
  startDate: null,
  numWeeks: null,
  duration: null,
  durationFrequency: DEFAULT_DURATION_UNIT,
  questions: [],
  regRemaining: null,
  regLimitThreshold: null,
  regLimit: null,
  otherFees: null
}
const PRICING = {
    id: null,
    tierLabel: null, 
    datetimeStart: null, 
    payByCheck: false,
    price: null
}
// @TODO questions
export default {
  name: 'EventForm',
  data() {
    return {
      latLng: {},
      location: {},
      newLocationPending: false,
      event: {},
      priceTeir: {
        id: null,
        tierLabel: null,
        datetimeStart: null,
        payByCheck: false,
        price: null
      },
      pricing: [],
      show: true,
      gMapOpts: {
          strictBounds: true,
          types: ["geocode","establishment"],
          componentRestrictions: {country: 'us'}
      },
      eventWelcome: {
        id: null,
        custom: false,
        message: null
      },
      adminFeeRequired: false
    }
  },
  computed: {
    ...mapGetters([
      'eventStatusOptions', 
      'eventLocations', 
      'eventTypes', 
      'sports', 
      'eventQuestions', 
      'business'
    ]),
    thisBusiness () {
      return this.currentBusiness['@id']
    },
    eventLocationOptions () {
      let ret = Array.from(this.eventLocations)
      ret.push({ '@id': 'new', name: 'New Location' })
      ret.push({ '@id': null, name: 'Select a Location', disabled: true })
      return ret
    },
    sportsOptions () {
      let ret = Array.from(this.sports)
      ret.push({ '@id': null, label: 'Select an Event Sport', disabled: true })
      return ret
    },
    eventTypeOptions () {
      let ret = Array.from(this.eventTypes)
      ret.push({ '@id': null, label: 'Select an Event Type', disabled: true })
      return ret
    },
    questionOptions () {
      let ret = [] // Array.from(this.eventQuestions)
      for (let i in this.eventQuestions) {
        ret.push({ '@id': this.eventQuestions[i]['@id'], label: this.eventQuestions[i].label})
      }
      // ret.push({ '@id': null, label: 'Select a Question', disabled: true})
      return ret
    }
  },
  watch: {
    'event.registrationEnds' (val) {
      if (val === null) {
        return
      }
      this.checkRegistrationDates()
    },
    'event.durationFrequency' (val) {
      if (val === null) {
        this.event.registrationEnds = null
      } else {
        this.setDefRegEndsDate()
      }
    },
    'event.type' () {
      if ( ONGOING_EVENT_TYPES.includes(this.event.type) ) {
        this.event.durationFrequency = null
        this.event.registrationEnds = null
      } else {
        // this.event.durationFrequency = DEFAULT_DURATION_UNIT
        // this.setDefRegEndsDate()
      }
    },
    pricing: {
      handler () {
        this.checkRegistrationDates()
      },
      deep: true
    },
    'eventWelcome.custom' (val) {
      if (val && this.eventWelcome.message === null) {
        this.eventWelcome.message = `Welcome to "${this.event.title}!"`
      }
    },
    adminFeeRequired (val) {
      if (!val) {
        this.event.otherFees = null
      } else {
        this.event.otherFees = {
          adminFee: {
            amount: (this.event.otherFees?.adminFee?.amount || 0),
            taxable: false
          }
        }
      }
    }
  },
  mounted () {
    this.getSports()
    this.getEventStatuses({
      archived: false
    })
    this.getEventTypes({
      archived: false
    })
    this.getEventLocations({
      business: this.currentBusiness.id,
      active: true
    })
    this.getEventQuestions()
    // this.pricing = JSON.parse(this.$el.dataset.pricing)
    this.location = {...LOCATION}
    this.event = {...EVENT}
    this.pricing = [{...PRICING}]
    this.event.business = this.currentBusiness['@id']
    // this.event.postDate = this.$moment().format('YYYY-MM-DD')
    // this.event.startDate = this.$moment().format('YYYY-MM-DD')
    // this.event.registrationEnds = this.$moment().format('YYYY-MM-DD')
    this.pricing[0].datetimeStart = this.event.postDate

    // load event for edit or duplicate
    this.loadEvent()
  },
  methods: {
    ...mapActions([
      'getEventStatuses', 
      'getEventLocations', 
      'getEventTypes', 
      'getEventQuestions', 
      'getSports',
      'getEventWelcomeMessage',
      'postEventWelcomeMessage',
      'putEventWelcomeMessage',
      'deleteEventWelcomeMessage',
      'getEvent',
      'postEventLocation', 
      'postEventPricing', 
      'putEventPricing', 
      'deleteEventPricing', 
      'postEvent', 
      'putEvent'
    ]),
    jsonArrToOptionsArr (jsonArr, valueParam, textParam, defText) {
      let ret = [], option = {}
      if (defText) {
        let option = {value: null, text: defText}
        ret.push(option)
      }
      for (let i in jsonArr) {
        option = {value: jsonArr[i][valueParam], text: jsonArr[i][textParam]}
        ret.push(option)
      }
      return ret
    },
    setPlace (place) {
      if (!place) return
      let streetAddr = ""
      this.location = {
        name: place.name,
        lat: place.geometry.location.lat().toString(),
        lng: place.geometry.location.lng().toString()
      }
      for (let i in place.address_components) {
        let type = place.address_components[i].types[0]
        if (type === "street_number") {
          streetAddr = place.address_components[i].short_name + ' ' + streetAddr
        } if (type === "route") {
          streetAddr = streetAddr + ' ' + place.address_components[i].short_name
        } if (type === "postal_code") {
          this.location.postalCode = place.address_components[i].short_name
        }
      }
      this.location.address1 = streetAddr
    },
    setDefRegEndsDate () {
      this.event.registrationEnds = this.event.registrationEnds || this.$moment(this.event.startDate).subtract(2, "weeks").toDate()
    },
    checkRegistrationDates () {
      // compares each Event Pricing date w/ Registration Ends date
      if (this.event.registrationEnds === null) {
        return
      }
      // set Registration Ends Compare Value
      let rECV = this.$moment(this.event.registrationEnds).format("YYYYMMDD")
      for (let i in this.pricing) {
        if (this.pricing[i].datetimeStart === null) {
          continue
        }
        // set current date Compare Value
        let thisDateCV = this.$moment(this.pricing[i].datetimeStart).format("YYYYMMDD")
        if (rECV < thisDateCV) {
          // comparison issue
          this.showError({ message: 'An Event Pricing date exceeds the registration end date.\nThat Event Pricing will not be used.' })
          // alert('An Event Pricing date exceeds the registration end date.\nThat Event Pricing will not be used.')
          return false
        }
      }
    },
    async addEventLocation () {
      this.newLocationPending = true
      let newLocation = null
      this.location.business = this.currentBusiness['@id']
      await this.postEventLocation(this.location).then(resp => {
        newLocation = resp['@id']
      })
      await this.getEventLocations({
        business: this.currentBusiness.id,
        active: true
      })
      this.event.location = newLocation
      this.newLocationPending = false
    },
    confirmOpenDate () {
      if (this.isOpenDate()) {
        alert('JSYK, the current date selection will set the status to \'OPEN\' upon saving.')
      }
    },
    isOpenDate () {
      if (this.event.status !== 'open') {
        let today = this.$moment().startOf('day').format('YYYYMMDD')
        for (let i in this.pricing) {
          let pDate = this.$moment(this.pricing[i].datetimeStart).startOf('day').format('YYYYMMDD')
            if (pDate <= today) {
              return true
            }
        }
      }
    },
    addNewPricing: function () {
      this.pricing.push(Vue.util.extend({}, this.priceTeir))
    },
    removePricing: function (index) {
      if (this.pricing[index].id !== null) {
        this.deleteEventPricing(this.pricing[index].id).then(() => {
          Vue.delete(this.pricing, index)
        })
      } else {
        Vue.delete(this.pricing, index)
      }
    },
    addNewQuestion: function () {
      this.event.questions.push(Vue.util.extend({}, {value: null}))
    },
    removeQuestion: function (index) {
      Vue.delete(this.event.questions, index)
    },
    submitPricing (eventIri) {
      for (let t in this.pricing) {
        let pricing = { ...this.pricing[t] }
        pricing.price = parseFloat(pricing.price) // format as float
        pricing.datetimeStart = this.$moment(pricing.datetimeStart).format('YYYY-MM-DD')
        // pricing.payByCheck = (pricing.payByCheck === 'true') // format as bool
        if (pricing.id === null) {
          pricing.event = eventIri
          this.postEventPricing(pricing) // add pricing
        } else {
          this.putEventPricing(pricing) // update pricing
        }
      }
    },
    submitWelcomeMessage () {
      if (!this.event.id) {
        throw new Error('Event ID is required to submit welcome message')
      }
      let welcomeMessage = { ...this.eventWelcome }
      let useCustom = welcomeMessage.custom
      delete welcomeMessage.custom // remove unused custom prop
      if (!welcomeMessage.id) {
        delete welcomeMessage.id
      }
      welcomeMessage.event = constants.IRI_EVENTS + this.event.id

      if (useCustom && !welcomeMessage.id) {
        this.postEventWelcomeMessage(welcomeMessage)
      } else if (useCustom) {
        this.putEventWelcomeMessage(welcomeMessage)
      } else if (welcomeMessage.id) {
        this.deleteEventWelcomeMessage(welcomeMessage.id)
      }
    },
    onSubmit(evt) {
      evt.preventDefault()
      // set int values
      let event = { ...this.event }
      event.ageMin = parseInt(this.event.ageMin)
      event.ageMax = parseInt(this.event.ageMax)
      event.regLimit = parseInt(this.event.regLimit)
      event.regLimitThreshold = parseInt(this.event.regLimitThreshold)
      event.regRemaining = parseInt(this.event.regRemaining)
      event.numWeeks = parseInt(this.event.duration)
      event.duration = parseInt(this.event.duration)
      event.postDate = this.$moment(this.event.postDate).format('YYYY-MM-DD')
      event.startDate = this.$moment(this.event.startDate).format('YYYY-MM-DD')
      event.registrationEnds = !this.event.registrationEnds ? null : this.$moment(this.event.registrationEnds).format('YYYY-MM-DD')
      if (this.event.id === null) {
        // add event
        this.postEvent(event).then(resp => {
          this.event.id = resp.id
          this.submitPricing(resp['@id'])
          this.submitWelcomeMessage()
          this.showSuccess({ message: 'the event has been created.' })
          this.$router.push('/events/edit/' + resp.id)
        })
      } else {
        // update event
        this.putEvent(event).then(resp => {
          this.submitPricing(resp['@id'])
          this.submitWelcomeMessage()
          this.showSuccess({ message: 'the event has been updated.' })
          // this.$router.push({ name: 'events' })
        })
      }
    },
    onReset(evt) {
      evt.preventDefault()
      if (! confirm('Reset the current form back to default values?')) {
        return
      }
      /* Reset our form values */
      this.location = {...LOCATION}
      this.event = {...EVENT}
      this.pricing = [{...PRICING}]
      /* Trick to reset/clear native browser form validation state */
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
      this.loadEvent()
    },
    setEventData (data) {
      let questions = []
      for (let i in data.questions) {
        questions[i] = data.questions[i]['@id']
      }
      let event = {
          id: data.id,
          business: data.business,
          status: data.status['@id'],
          location: data.location['@id'],
          type: data.type['@id'],
          sport: data.sport['@id'],
          title: data.title,
          summary: data.summary,
          description: data.description,
          ageMin: data.ageMin,
          ageMax: data.ageMax,
          registrationEnds: data.registrationEnds ? new Date(data.registrationEnds) : null,
          payByCheck: data.payByCheck,
          postDate: new Date(data.postDate),
          startDate: new Date(data.startDate),
          numWeeks: data.numWeeks,
          duration: data.duration,
          durationFrequency: data.durationFrequency,
          questions: questions,
          regRemaining: data.regRemaining,
          regLimitThreshold: data.regLimitThreshold,
          regLimit: data.regLimit,
          otherFees: data.otherFees
        }
      this.event = event
      
      if (this.event.otherFees?.adminFee?.amount > 0) {
        this.adminFeeRequired = true
      }
      
      // pricing
      let pricing = []
      for (let i in data.pricing) {
        let obj = {}
        obj.id = data.pricing[i].id
        obj.tierLabel = data.pricing[i].tierLabel
        obj.price = data.pricing[i].price
        obj.datetimeStart = this.$moment(data.pricing[i].datetimeStart, 'YYYY-MM-DD').toDate(),
        obj.payByCheck = data.pricing[i].payByCheck,
        pricing.push(obj)
      }
      this.pricing = pricing
    },
    async loadEvent () {
      let eid = null

      if (typeof this.$route.query.eid !== 'undefined') {
        eid = this.$route.query.eid
      } else if (typeof this.$route.params.eid !== 'undefined') {
        eid = this.$route.params.eid
      }

      if (eid === null) { return }

      const event = await this.getEvent({
        id: eid,
        business: this.business.id
      })

      if (!event) {
        this.$router.push({name: 'events'})
        this.showError({ message: "the event could not be found" })
      }

      // if (event.duration === null) {
      //   event.duration = event.numWeeks
      //   event.durationFrequency = DEFAULT_DURATION_UNIT
      // }
      this.setEventData(event)
      const welcomeMessage = await this.getEventWelcomeMessage(event.id)

      if (welcomeMessage?.id) {
        this.eventWelcome.custom = true
        this.eventWelcome.id = welcomeMessage.id
        this.eventWelcome.message = welcomeMessage.message
      }

      // are we duplicating the event?
      if (this.$route.query.duplicate || this.$route.name === 'eventDuplicate') {
        // set the event ID as null so the event will save as new
        this.event.id = null
        this.event.registrationEnds = null
        this.event.postDate = null
        this.event.startDate = null
        this.pricing = [{ ...PRICING }]
      }
    }
  }
}
</script>
